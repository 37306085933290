
import SearchPartNumber from '@/components/SearchPartNumber/SearchPartNumber.vue';
import Cart from '../ShoppingCart/Cart.vue';
import {StoreProxy} from "@/api/common/StoreProxy";
import ChangePassword from "../ChangePassword/ChangePassword.vue";
import {UserPermissions} from "@api/core/UserPermissions";
import {Roles} from "@api/core/Roles";


export default {

    name: 'Toolbar',
    props: ['menuBurger'],
    data() {
        return {
            popoverWidth: 300,
            fullscreen: false,
            lang: 'us',
            showChangePassword: false,
        }
    },
    components: {
        SearchPartNumber,
        Cart,
        ChangePassword
    },
    computed: {
        hidePartsCatalog(){
            return (StoreProxy.getCompanyId(this.$store) === window.config.aerCompanyId);
        },
        customerName(){

          if (!StoreProxy.isImpersonated(this.$store)){
              return StoreProxy.getCustomerName(this.$store);
          }
          else{
              return StoreProxy.getImpersonatedCustomerName(this.$store);
          }
        },
        customerNumber(){

            if (!StoreProxy.isImpersonated(this.$store)){
                return StoreProxy.getCustomerNumber(this.$store);
            }
            else{
                return StoreProxy.getImpersonatedCustomerNumber(this.$store);
            }
        },
        canImpersonate(){
          return UserPermissions.canImpersonate(this.$store);
        },
        hasAccessToDealerEMailNotifications(){
            return UserPermissions.hasNotRoles(this.$store, Roles.ADMIN, Roles.DEALER_ADMIN);
        }
    },
    methods: {
        onCommand(path) {

           if (path === "change-password"){
                this.showChangePassword = true;
                console.debug('[d349740] show change password dialog');
            }
            else {

                StoreProxy.Save(this.$store);
                this.$router.push(path)
            }
        },
        toggleSidebar() {
            this.$emit('toggle-sidebar')
        },
        resizePopoverWidth() {

            if(window.innerWidth <= 768) {
                this.popoverWidth = 250
            } else {
                this.popoverWidth = 300
            }

        },
        toggleFullscreen() {

            this.$fullscreen.toggle(document.querySelector('body'), {
                wrap: false,
                callback: () => {this.fullscreen = this.$fullscreen.getState()}
            })
        },
        changePasswordClosed(){
            this.showChangePassword = false;

        },

    },

    mounted() {
        this.fullscreen = this.$fullscreen.getState();
        this.resizePopoverWidth();
        window.addEventListener('resize', this.resizePopoverWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizePopoverWidth);
    }
}