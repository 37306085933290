import {HttpUtils} from "@api/common/HttpUtils";
import config from './../_configs';
import {Tracer} from "@api/common/Tracer";
import Utils from "../_lib/utils";
import {StringUtils} from "@api/common/StringUtils";

// noinspection JSUnusedGlobalSymbols
/**
 * @private
 * Communicates directly with the API
 *
 */
export class PartCatalogApiClient {

    /**
     * @param auth - The auth object as defined in the vuex store.
     */
    constructor(auth) {
        this._auth = auth;

        if (auth == null) {
            throw new Error("6H5QQP-(catalog_search):#NULL_ARGUMENT!: Auth is required to init CatalogSearch");
        }
    }

    getPartInfo(partNumber) {

        const url = `${config.catalogPartsApiUrl}/${partNumber}`;
        let vm = this;
        return new Promise((resolve, reject) => {

            HttpUtils.get(url, vm._auth).then(data => {
                resolve(data);
            }).catch(err => {

                Tracer.current.error(err, {
                    message: "7I0TVD-(part-info): Unable to get part information",
                    partNumber: partNumber,
                    url: url
                });

                reject(err);
            });
        });
    }

    /**
     * Gets the list of categories that match the filters provided
     * @param filters
     * @returns {Promise<unknown>}
     */
    getCategories(filters) {

        let vm = this;
        return new Promise((resolve, reject) => {
            let url = `${config.catalogApiUrl}/categories?${Utils.CreateParamsFromFilters(filters)}`;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    resolve([]);
                }
            }).catch(err => {
                Tracer.current.error("5ACRJF (Catalog Search)[get_categories]: Error while getting the categories");
                Tracer.current.error(err);
                reject(err);
            });

        });
    }

    getAllCategories() {

        let vm = this;
        return new Promise((resolve, reject) => {
            let url = `${config.catalogApiUrl}/categories/all`;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    resolve([]);
                }
            }).catch(err => {
                Tracer.current.error("5ACRJF-(catalog_search):[get_all_categories]: Error while getting the categories");
                Tracer.current.error(err);
                reject(err);
            });

        });
    }

    getYears() {

        let vm = this;
        return new Promise((resolve, reject) => {
            let url = `${config.catalogApiUrl}/years`;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    Tracer.current.error("4P3ZYF-(catalog_search):[Get Years]: API returned empty array of years.");
                    reject(null);
                }
            }).catch(err => {
                Tracer.current.error("5ACRJF-(catalog_search):[Get Years]  Error while getting the available list of years");
                Tracer.current.error(err);
                reject(err);
            });
        });

    }

    /**
     * Gets a list of makes based on the filters (criteria)
     * @param filters
     * @returns {Promise<unknown>}
     */
    getMakes(filters) {

        let vm = this;
        return new Promise((resolve, reject) => {
            let params = Utils.CreateParamsFromFilters(filters);

            let url = `${config.catalogApiUrl}/makes?${params}`;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    Tracer.current.error("7Y732Q-(catalog_search):[Get Makes]: No makes returned");
                    resolve([]);
                }
            }).catch(err => {
                Tracer.current.error("ESTNJD-(catalog_search):[Get Makes]: Error when getting the list of makes");
                Tracer.current.error(err);
                reject(err);
            });
        });

    }

    /**
     * Get the list of automobile models based on the filters (criteria) provided in the argument
     * @param filters
     * @returns {Promise<unknown>}
     */
    getModels(filters) {

        let vm = this;
        return new Promise((resolve, reject) => {
            let url = `${config.catalogApiUrl}/models?${Utils.CreateParamsFromFilters(filters)}`;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    Tracer.current.error("7Y732Q-(catalog_search)(models): No models returned.");
                    resolve([]);
                }
            }).catch(err => {
                Tracer.current.error("ESTNJD-(catalog_search)(models): There is an error occurring when attempting to retrieve the list of models.");
                Tracer.current.error(err);
                reject(err);
            });

        });
    }

    /**
     * Gets the specific conditions available based on the criteria provided
     * @param filters
     * @returns {Promise<unknown>}
     */
    getSpecificConditions(filters)  {

        return new Promise((resolve, reject) => {
            let url = `${config.catalogApiUrl}/specific-conditions?${Utils.CreateParamsFromFilters(filters)}`;

            let vm = this;
            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    resolve(null);
                }
            }).catch(err => {
                Tracer.current.error("F7FKEI-(catalog_search)[specific_conditions]: Error while getting the specific conditions");
                Tracer.current.error(err);
                reject(err);
            });

        });

    }

    /**
     * Search parts based on the filters (criteria) provided.
     * @param filters
     * @returns {Promise<unknown>}
     */
    searchParts(filters){
        return new Promise((resolve, reject) => {

            const url = `${config.catalogPartsApiUrl}?${Utils.CreateParamsFromFilters(filters)}`;

            const vm = this;

            HttpUtils.get(url, vm._auth).then(data => {

                if (data != null) {
                    resolve(data);
                } else {
                    resolve([]);
                }

            }).catch(err => {
                Tracer.current.error("DMEYMG-(part_catalog)(search_parts): An error occurred while attempting to search for parts.");
                Tracer.current.error(err);
                reject(err);
            });

        });

    }

    /**
     * Search parts based on the vehicle VIN's number.
     * @param vin
     * @param categoryId
     *
     * @returns {Promise<any>}
     */
    searchPartsByVin(vin, categoryId){
        return new Promise((resolve, reject) => {


            let url = `${config.catalogPartsApiUrl}/vin/${vin}/${categoryId}`;

            if (categoryId == null) {
                url = `${config.catalogPartsApiUrl}/vin/${vin}`;
            }

            const vm = this;

            vin = vin.trim();

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    Tracer.current.info("E1TJ3F-(part_catalog)(search_by_vin): No data found!");
                    resolve(null);
                }
            }).catch(err => {
                Tracer.current.error("DO2RRD-(part_catalog)(search_by_vin): Error while search for parts.");
                Tracer.current.error(err);
                reject(err);
            });

        });
    }

    /**
     * Gets a list of compatible vehicles for the part number provided
     * @param partNumber
     * @returns {Promise<object|array>}
     */
    getCompatibleVehicles(partNumber){
        return new Promise((resolve, reject) => {
            let url = `${config.baseUrl}/v1/vehicles/${partNumber}/comps`;

            let vm = this;

            HttpUtils.get(url, vm._auth).then(data => {
                if (data != null) {
                    resolve(data);
                } else {
                    resolve([]);
                }
            }).catch(err => {
                Tracer.current.error("ANDF2A-(catalog_search):[Get Compatible Vehicles]: Error while searching for compatible vehicles for part number "+partNumber);
                Tracer.current.error(err);
                reject(err);
            });

        });

    }

}