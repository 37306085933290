import Vue from 'vue'
import Router from 'vue-router'

//apps
import PartsCatalog from '../views/apps/PartsCatalog/PartsCatalog.vue';
import ShoppingCart from '../views/apps/ShoppingCart/ShoppingCart.vue';
import PlaceOrder from '../views/apps/PlaceOrder/PlaceOrder.vue';
import PlaceOrderConfirm from '../views/apps/PlaceOrder/PlaceOrderConfirm/PlaceOrderConfirm.vue';
import OrderHistory from '../views/apps/OrderHistory/OrderHistory.vue';
import OrderDetailView from '../views/apps/OrderHistory/parts/OrderDetailView/OrderDetailView.vue';
import OrderDetailPdfViewer from "../views/apps/OrderHistory/parts/OrderDetailPdfViewer/OrderDetailPdfViewer.vue";
import MarketingMaterials from "../views/apps/Tools/MarketingMaterials/MarketingMaterials.vue";
import OrderParts from "../views/apps/OrderParts/OrderParts.vue";


//pages
import Login from '../views/apps/Login/Login.vue'

//ui
import layouts from '../layout'
import store from '../store'
import {Authentication} from "@/api/modules/auth/Authentication";
import EmailNotifications from "@/views/apps/EmailNotifications/EmailNotifications.vue";

Vue.use(Router);

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: '/order-parts',
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/order-parts/:partNumber?/:add?',
			name: 'order-parts',
			component: OrderParts,
			props: { isEmergencyOrder: false },
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/emergency-order/:partNumber?/:add?',
			name: 'emergency-order',
			component: OrderParts,
			props: { isEmergencyOrder: true },
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/partscatalog/:partNumber?',
			name: 'partscatalog',
			component: PartsCatalog,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/order-history',
			name: 'order-history',
			component: OrderHistory,
			children: [
				{
					path: 'order-details/:type/:orderNumber',
					component: OrderDetailView
				},
				{
					path: 'pdf/:type/:id',
					component: OrderDetailPdfViewer
				}
			],
			meta: {
				auth: true,
				layout: layouts.navTop
			}

		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: {
				layout: layouts.contenOnly
			}
		},
		{
			path: '/logout',
			beforeEnter(to, from, next) {

				Authentication.logout(store).then(() => {
					window.tracer.debug("[d565492] Succesfully logout user")
					next({path: '/login'})
				}).catch(error => {
					window.app.$notify.error({
						title: 'Server Error',
						message: 'Failure to log out. Please delete cookies, if problem persists contact support!'
					});

					window.tracer.error(error);

				});

			}
		},
		{
			path: '/shopping-cart',
			name: 'shopping-cart',
			component: ShoppingCart,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/place-order',
			name: 'place-order',
			component: PlaceOrder,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/place-order/confirm',
			name: 'place-order-confirm',
			component: PlaceOrderConfirm,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/tools/materials',
			name: 'tools-materials',
			component: MarketingMaterials,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/settings/email-notifications',
			name: 'settings-email-notifications',
			component: EmailNotifications,
			meta: {
				auth: true,
				layout: layouts.navTop
			}
		},
		{
			path: '/dealer',
			redirect: '/dealer',
			name: 'dealer-admin',

		},
		{ path: '*', redirect: '/' }
	]
});


const l = {
	contenOnly(){
		store.commit('setLayout', layouts.contenOnly)
	},
	navLeft(){
		store.commit('setLayout', layouts.navLeft)
	},
	navRight(){
		store.commit('setLayout', layouts.navRight)
	},
	navTop(){
		store.commit('setLayout', layouts.navTop)
	},
	navBottom(){
		store.commit('setLayout', layouts.navBottom)
	},
	set(layout){
		store.commit('setLayout', layout)
	}
};

//insert here login logic
const auth = {
	loggedIn() {
		return store.getters.isLogged;
	},
	logout() {
		store.commit('setLogout')
	}
};

router.beforeEach((to, from, next) => {
	let authrequired = true;

	if(to && to.meta && to.meta.auth) {
		authrequired = true
	}

	
	if(authrequired) {

		if(auth.loggedIn()) {
			if(to.name === 'login') {
				window.location.href = '/';
				return false
			} else { 
				next()
			}
		} else {
			if(to.name !== 'login'){
				window.location.href = '/login';
				return false
			}
			next()
		}
	} else {
		if(auth.loggedIn() && to.name === 'login'){
			window.location.href = '/';
			return false
		} else {
			next()
		}
	}

	if(to && to.meta && to.meta.layout){
		l.set(to.meta.layout)
	}	
});

router.afterEach((to, from) => {
	setTimeout(()=>{
		store.commit('setSplashScreen', false)
	}, 500)
});

export default router