
import {StoreProxy} from "@/api/common/StoreProxy";
import {UserPermissions} from "@api/core/UserPermissions";
import {Authentication} from "@/api/modules/auth/Authentication";
import {StringUtils} from "@api/common/StringUtils";

export default {
    name: 'Login',
    data() {
        return {
            userId: '',
            password: '',
            failedLogin: {
                attempted: false,
                message: ''
            }
        }
    },
    computed:{
      versionLabel(){
          return `${$build.version}-${$build.shortCommitHash} | build: ${$build.buildNumber} ${$build.date}`
      }
    },
    mounted() {

    },
    methods: {

        login() {

            if (StringUtils.isEmptyOrNull(this.userId) || StringUtils.isEmptyOrNull(this.password)){
                this.failedLogin.attempted = true;
                this.failedLogin.message = "User ID and Password are required.";

                return;
            }

            Authentication.login(this.$store, this.userId, this.password).then( () => {
                this._checkIfUserIsRestricted();

            }).catch(e => {

                if (e != null) {
                    this.failedLogin.attempted = true;
                    this.failedLogin.message = "Server Error - Please try again later. ";
                    window.tracer.error(e);
                }
                else{
                    this.failedLogin.attempted = true;
                    this.failedLogin.message = "Couldn't find your account. Please verify your credentials.";
                }
            });

        },
        _checkIfUserIsRestricted(){

            let vm = this;
            let isAccountRestricted = StoreProxy.isRestricted(this.$store);
            let isSalesOnly = UserPermissions.isSalesRole(this.$store) && !UserPermissions.canImpersonate(this.$store);

            window.tracer.debug(`#64098R:[ACCOUNT_RESTRICTED=${isAccountRestricted}]:[ACCOUNT_SALES_ONLY=${isSalesOnly}]`);

            if (isAccountRestricted && isSalesOnly){

                const h = vm.$createElement;
                this.$msgbox({
                    title: 'Account is restricted',
                    message: h('div', {class: ''}, [
                        h('div', null, 'Your login is currently restricted. A dealer account has not been assigned yet.'),
                        h('div', {style: 'font-weight: bold; margin-top: 5px;'}, 'Please contact support')
                    ]),
                    confirmButtonText: 'Ok',
                    showCancelButton: false,
                    type: 'warning',
                    callback: action => {
                        vm.$router.replace('/logout');
                    }
                });

            }
            else {
                vm.$router.push('/');
            }
        }


    }
}