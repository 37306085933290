import {PartCatalogApiClient} from "./_comm/PartCatalogApiClient";
import {IVehicle} from "./models/IVehicle";
import {Vehicle} from "./models/Vehicle";
import {IPartResults} from "./models/IPartResults";
import {Tracer} from "../../common/Tracer";
import {CatalogSearchArgs} from "./CatalogSearchArgs";
import {IVehicleCondition} from "./models/IVehicleCondition";
import {VinValidator} from "../../common/VinValidator";

/** @todo: rename this class to `PartsSearch` to better represents its purpose
 *  @work-item: AER-808
 * **/

export class PartCatalogSearch {

    _auth : string;
    _catalogClient : PartCatalogApiClient;

    constructor(auth : string) {
        this._auth = auth;

        if (auth == null) {
            throw new Error("2VNLOU-(parts_search)[AUTH]: Auth is required to init CatalogSearch");
        }

        this._catalogClient = new PartCatalogApiClient(this._auth);
    }


    /**
     * Search for parts based on the filters provided.
     * @param catalogSearchArgs
     */
    searchParts(catalogSearchArgs : CatalogSearchArgs) : Promise<IPartResults> {

        return new Promise((resolve, reject) => {

            const filters = {
                year: catalogSearchArgs.year,
                make: catalogSearchArgs.makeId,
                model: catalogSearchArgs.modelId,
                category: catalogSearchArgs.categoryId,
                engine: catalogSearchArgs.engineId,
                driveType: catalogSearchArgs.driveId,
                fuelType: catalogSearchArgs.fuelId,
                engineVin: catalogSearchArgs.vinId
            }

            return this._catalogClient.searchParts(filters).then(data => {
                if (data == null || data.length == 0){
                    const empty : IPartResults = <IPartResults>{};
                    resolve(empty);
                    return
                }

                const result : IPartResults = data;
                resolve(result);
            }).catch(err => {
                Tracer.current.error(err, "F16R0T-(search_parts): Unable to get part information");
                reject(err)
            });
        });

    }


    /**
     * Returns a list of parts that matches the vehicle vin provided.
     * @param {string} vin - The vin of the vehicle.
     * @param {string} categoryId - The part category identifier.
     *
     * @returns {Promise<IVehicle>}
     */
    searchPartsByVin(vin : string, categoryId? : string) : Promise<IVehicle | null> {

        return new Promise((resolve, reject) => {

            if (vin == null) {
                reject(new Error("2OIRU5-(search_parts): VIN cannot be empty or null."));
                return;
            }

            vin = vin.trim().toUpperCase();

            if (!VinValidator.validate(vin)) {
                reject(new Error("E79XYW-(search_parts): VIN check digit is invalid."));
                return;
            }

            return this._catalogClient.searchPartsByVin(vin, categoryId).then(data => {

                if (data == null) {
                    resolve(null);
                    return
                }

                const vehicle : Vehicle = new Vehicle();
                vehicle.setResults(data.results)
                vehicle.setYear(data.vehicle.year);
                vehicle.setMake(data.vehicle.make.id, data.vehicle.make.name);
                vehicle.setModel(data.vehicle.model.id, data.vehicle.model.name);
                vehicle.setFuelType(data.vehicle.fuelType.id, data.vehicle.fuelType.name);
                vehicle.setEngineLiters(data.vehicle.engineLiters.id, data.vehicle.engineLiters.name);
                vehicle.setEngineVin(data.vehicle.engineVin.id, data.vehicle.engineVin.name);

                data.vehicle.driveType.forEach(function (item: IVehicleCondition): void {
                    vehicle.setDriveType(item.id, item.name);
                });


                resolve(vehicle);

            }).catch(err => {
                Tracer.current.error(err, "555MWQ-(search_parts): Unable to get part information")
                reject(err)
            });
        });

    }


    /**
     * Search for a specfic part number.
     * @param {string }partNumber - The part number.
     */
    searchPartsNumber(partNumber : string) : Promise<IPartResults | null> {

        return new Promise((resolve, reject) => {

            if (partNumber == null) {
                reject(new Error("4ZR0XS-(parts_search): partNumber cannot be empty or null."));
                return;
            }


            return this._catalogClient.getPartInfo(partNumber).then(data => {

                if (data == null || data.length == 0) {
                    const empty: IPartResults = <IPartResults>{};
                    resolve(empty);
                    return
                }

                const result: IPartResults = data;
                resolve(result);

            }).catch(err => {
                Tracer.current.error(err, "D4XARU-(search_parts): Unable to get part information")
                reject(err)
            });
        });

    }


    /** @todo: move this method to the Catalog object.
     *  @work-item: AER-809
     * **/

    /**
     * Gets all available part category.
     */
    getAllCategories() : Promise<IVehicleCondition[]> {

        return new Promise((resolve, reject) => {
            return this._catalogClient.getAllCategories().then(data => {

                const result : IVehicleCondition[] = data;
                resolve(result);

            }).catch(err => reject(err));

        });
    }
}