
import './config/config';
import './constants';
import { AppConfig } from './config/AppConfig';

import 'babel-polyfill';
import 'es6-promise/auto';

import Vue from 'vue';
import './assets/tailwind.css';

import Vuebar from 'vuebar';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import Fullscreen from 'vue-fullscreen';
import vClickOutside from 'v-click-outside';
import VirtualList from 'vue-virtual-scroll-list';
import VueResize from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';
import VueFloatLabel from 'vue-float-label';
import VuePerfectScrollbar from './components/vue-ps';
import * as Vue2Leaflet from 'vue2-leaflet';


import 'leaflet/dist/leaflet.css';

import './assets/scss/global.scss';
import 'flex.box';
import 'animate.css';
import '../node_modules/mdi/scss/materialdesignicons.scss';


import Affix from './directives/affix';
import App from './App.vue';
import router from './router/';
import store from './store' ;
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue2Filters from 'vue2-filters';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import { detect } from 'detect-browser'
import {Tracer} from "@api/common/Tracer";
import {AppBuild} from "@/init/AppBuild";
import {AppCache} from "@api/common/AppCache";




const browser = detect();

/**
 * Browser information accessible in the application.
 * @type {BrowserInfo | BotInfo | NodeInfo}
 */
window.browser = browser;
Vue.prototype.$config = window.config;


Vue.config.errorHandler = (err, vm, info) => {
	Tracer.current.error(err);
	throw err; // rethrow
};


Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(Vuebar);
Vue.use(VueFloatLabel);
Vue.use(VueResize);
Vue.use(Fullscreen);
Vue.use(Element, {locale});
Vue.use(vClickOutside);
Vue.use(Viewer);
Vue.directive('affix', Affix);

Vue.use(VueAxios, axios);
Vue.use(Vue2Filters);

Vue.component('vue-scroll', VuePerfectScrollbar);
Vue.component('vue-virtual-list', VirtualList);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'), 
	iconUrl: require('leaflet/dist/images/marker-icon.png'), 
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
Vue.component('l-map', Vue2Leaflet.LMap);
Vue.component('l-marker', Vue2Leaflet.LMarker);
Vue.component('l-tile-layer', Vue2Leaflet.LTileLayer);

if (browser != null){
	console.log(`${browser.name} ${browser.version} (${browser.os})`);

	if (browser.name === 'ie'){

		try {
			let verRegex = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/
			let ver = browser.version.match(verRegex);
			if (ver != null) {
				let vernum = parseInt(ver[0]);
				if (vernum < 11) {
					alert('This browser version is not supported.')
				}

				if (vernum >= 11 && vernum <= 12) {
					window.config.isIE11 = true;
					console.log('[i981270] It is Internet Explorer 11. ')
				}
			}
		}catch(error){
			window.tracer.error(error);
		}

	}
}



/**
 * Tracer (logger) for the application
 * @type {Tracer}
 */
window.tracer = new Tracer();

let appBuild = new AppBuild();
appBuild.setDate(process.env.VUE_APP_BUILD_DATE);
appBuild.setEnv(process.env.VUE_APP_ENVIRONMENT);
appBuild.setDebugMode(process.env.VUE_APP_IS_DEBUG_MODE);
appBuild.setCommitHash(process.env.VUE_APP_COMMIT_HASH);
appBuild.setStamp(process.env.VUE_APP_BUILD_STAMP);
appBuild.setVersion(process.env.VUE_APP_VERSION);
appBuild.setBuildNumber(process.env.VUE_APP_CI_BUILD);

Tracer.current.setTag("commit-hash", process.env.VUE_APP_COMMIT_HASH);
Tracer.current.setTag("build", process.env.VUE_APP_CI_BUILD);

/*
 * Build information
 * @type {AppBuild}
 */
window.$build = appBuild;

Tracer.current.info(`140550-(app): Version ${appBuild.version} (${appBuild.shortCommitHash}) build on ${appBuild.date}`);
if (appBuild.env === "Production"){
	Tracer.current.info("Production Version");
}


window.$instance = {};

if (window.appInstanceId != null) {

	Vue.prototype.$instanceId = window.appInstanceId;
	window.$instance.id = window.appInstanceId;
	window.$instance.sessionCookieName = `${window.config.appPrefix}_${window.appInstanceId}`;

}
else{

	Vue.prototype.$instanceId = '';
	window.appInstanceId = '';
	window.$instance.id = '';
	window.$instance.sessionCookieName = ``;
}

Tracer.current.setTag("instanceId", window.appInstanceId);
Tracer.current.setTag("correlationId", window.config.correlationId);
Tracer.current.setTag("version", appBuild.version);
Tracer.current.setTag("environment", AppConfig.current.environment);
/**
 * Gets the current Tracer instances
 * @type {Tracer|*}
 */
Vue.prototype.$tracer = window.tracer;
Vue.prototype.$build = window.$build;

/**
 * Access to the application config class
 * @type {AppConfig}
 */
Vue.prototype.$appConfig = AppConfig.current;


Tracer.current.info(`4RYLZL-(app): ENVIRONMENT: ${AppConfig.current.environment}`);


let app = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
// allow me to access the app.
/**
 * Gets the current VUE app
 * @type {Vue | object | Record<never, any>}
 */
window.app = app;
window.$debugMode = false;

window.setTimeout(AppCache.purge, 250);










