import {PartNumberSearch} from "@/api/modules/partNumberSearch/PartNumberSearch";
import {StoreProxy} from "@/api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";

export default {
    name: "SearchPartNumber",
    data() {
        return {
            partNumberSearch: new PartNumberSearch(StoreProxy.getAuth(this.$store)),
            text: ''
        }
    },
    computed:{
        isActive(){
            return false;
            // ** temporarly disabled.
            // return StoreProxy.isActive(this.$store);
        }
    },
    methods: {
        fetchParts(text, cb) {
            const promise = this.partNumberSearch.searchDescription(text);

            promise.then((data) => {

                if (data.count === 0){
                    cb([]);
                }
                else{
                    cb(data.items);
                }

            }).catch((rej) => {

                Tracer.current.error('EC3M4L4-(search_part_number): Error when fetching possible part numbers.');
                Tracer.current.error(rej);
                cb([]);

            });
        },
        selectedPart(item){
            this.onItemSelected(item.partNumber);
            this.text = '';
        },
        onItemSelected(partNumber){

            this.$router.replace({ path: `/${this.$router.currentRoute.name}/${partNumber}/add`});
            this.text = '';
        }
    }

}